import React from 'react'
import SectionWrapper from '../SectionWrapper/SectionWrapper'
import AboutUsTilesBackground from './AboutUsTilesBackground'
import PropTypes from 'prop-types'
import { AboutUsTilePropType, HeaderWithDescriptionPropType } from '../propTypes'
import LeftTiles from './LeftTiles'
import RightTiles from './RightTiles'
import AboutUsDetails from './AboutUsDetails'
import { aboutUsTiles } from '../consts'
import { groupBy } from 'lodash'

const AboutUsTiles = ({ headerWithDescription, tiles }) => {
  tiles = tiles.map((tile, index) => ({ ...tile, ...aboutUsTiles[index] }))
  tiles = groupBy(tiles, 'position')
  const leftTiles = tiles.left
  const rightTiles = tiles.right

  return <SectionWrapper
    className='about-us-tiles'
    id='aboutUsTiles'
  >
    <AboutUsTilesBackground />
    <h2 className='about-us-tiles__header'>{headerWithDescription?.header}</h2>
    <AboutUsDetails headerWithDescription={headerWithDescription} />
    <div className='d-flex justify-content-center flex-column flex-sm-row'>
      <LeftTiles tiles={leftTiles} />
      <RightTiles tiles={rightTiles} />
    </div>
  </SectionWrapper>
}

export default AboutUsTiles

AboutUsTiles.propTypes = {
  headerWithDescription: HeaderWithDescriptionPropType,
  tiles: PropTypes.arrayOf(AboutUsTilePropType)
}

AboutUsTiles.defaultProps = {
  headerWithDescription: '',
  tiles: []
}
