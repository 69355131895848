import React from 'react'
import AboutUsTiles from './AboutUsTiles/AboutUsTiles'
import Header from './Header/Header'
import HomePageTiles from './HomePageTiles/HomePageTiles'
import WorkWithUsTiles from './WorkWithUsTitles/WorkWithUsTiles'
import ServiceTiles from './ServiceTiles/ServiceTiles'
import AboutUs from './AboutUs/AboutUs'
import Categories from './Categories/Categories'
import MestInBriefWithLogo from './MestInBriefWithLogo/MestInBriefWithLogo'
import MestInBriefWithImage from './MestInBriefWithImage/MestInBriefWithImage'
import SwappingBackgroundHeader from './SwappingBackgroundHeader/SwappingBackgroundHeader'

const MissingComponent = ({ strapiComponent, ...props }) =>
  <div>
    <h2>Strapi component name: {strapiComponent}</h2>
    <pre>{JSON.stringify(props, null, 2)}</pre>
  </div>

export const Components =
  {
    'about-us-tiles': {
      Component: AboutUsTiles,
      queryToProps: (queryData) => queryData
    },
    'header': {
      Component: Header,
      queryToProps: (queryData) => queryData
    },
    'swapping-bg-header': {
      Component: SwappingBackgroundHeader,
      queryToProps: (queryData) => queryData
    },
    'about-us': {
      Component: AboutUs,
      queryToProps: (queryData) => queryData
    },
    'home-page-tiles': {
      Component: HomePageTiles,
      queryToProps: (queryData) => queryData
    },
    'work-with-us-tiles': {
      Component: WorkWithUsTiles,
      queryToProps: (queryData) => queryData
    },
    'service-tiles': {
      Component: ServiceTiles,
      queryToProps: (queryData) => queryData
    },
    'categories': {
      Component: Categories,
      queryToProps: (queryData) => queryData
    },
    'mest-in-brief-with-logo': {
      Component: MestInBriefWithLogo,
      queryToProps: (queryData) => queryData
    },
    'mest-in-brief-with-img': {
      Component: MestInBriefWithImage,
      queryToProps: (queryData) => queryData
    }
  }

export const renderSection = ({
  id,
  strapi_component: strapiComponent,
  scrollId,
  ...queryData
}) => {

  console.log(`Mapping Strapi: ${strapiComponent}`)
  const [, componentName] = strapiComponent.toLowerCase().split('.')
  const { Component, queryToProps } = Components[componentName] || {
    Component: MissingComponent,
    queryToProps: (props) => ({ strapiComponent, ...props })
  }
  console.log(`To Gatsby: ${Component.name}`)
  console.log('Mapping component props', queryData, queryToProps(queryData))

  const htmlId = scrollId || `${id}-${componentName}`

  return (
    <Component
      key={htmlId} {...queryToProps(queryData)}
      id={htmlId}
    />
  )
}
