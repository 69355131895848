import React from 'react'
import TileImage from './TileImage'
import PropTypes from 'prop-types'
import { StrapiImageWithLinesPropType } from '../propTypes'

const TileContent = ({ header, image, stripesBackground, imageClassName, description }) => <>
  <h3 className='d-flex align-items-center'>{header}</h3>
  <TileImage
    image={image}
    stripesBackground={stripesBackground}
    className={imageClassName}
  />
  <h5 className='font-montserrat'>{description}</h5>
</>

export default TileContent

TileContent.propTypes = {
  header: PropTypes.string,
  description: PropTypes.string,
  image: StrapiImageWithLinesPropType,
  stripesBackground: PropTypes.bool,
  imageClassName: PropTypes.string,
}

TileContent.defaultProps = {
  header: '',
  description: '',
  image: {},
  stripesBackground: false,
  imageClassName: '',
}
