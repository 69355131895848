import { isBrowser } from '../components/consts'

const useLocation = () => {
  let location

  if (isBrowser) {
    location = window.location.pathname
  }

  return location
}

export default useLocation
