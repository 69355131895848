import React from 'react'
import SectionWrapper from '../SectionWrapper/SectionWrapper'
import { Row } from 'react-bootstrap'
import AboutUsColumn from './AboutUsColumn'
import AboutUsBackground from './AboutUsBackground'
import PropTypes from 'prop-types'
import { AboutUsColumnPropType } from '../propTypes'
import classNames from 'classnames'

const getAboutUsClassName = (aboutUsColumns) => classNames(
  { 'about-us-with-two-headers': aboutUsColumns[0]?.header && aboutUsColumns[1]?.header },
  'about-us'
)

const AboutUs = ({ aboutUsColumns }) =>
  <SectionWrapper
    className={getAboutUsClassName(aboutUsColumns)}
    id='aboutUs'
  >
    <AboutUsBackground />
    <Row className='d-flex justify-content-start flex-column flex-md-row'>
      {aboutUsColumns.map((aboutUsColumn, index) =>
        <AboutUsColumn
          key={index}
          {...aboutUsColumn}
        />)}
    </Row>
  </SectionWrapper>

export default AboutUs

AboutUs.propTypes = {
  aboutUsColumn: PropTypes.arrayOf(AboutUsColumnPropType),
}

AboutUs.defaultProps = {
  aboutUsColumn: [],
}
