import React from 'react'
import { Col, Row } from 'react-bootstrap'
import { Link } from 'gatsby'
import classNames from 'classnames'
import useLocation from '../../hooks/useLocation'
import PropTypes from 'prop-types'
import { CategoriesTilePropType } from '../propTypes'
import { getAllCategoriesForPage } from '../helpers'

const getCategoryButtonClassName = (location, url, categoriesTiles) => classNames(
  'categories-buttons__button',
  { 'categories-buttons__button--active': location === url },
  { 'categories-buttons__button--small': categoriesTiles.length === 4 }
)

const getLgGridValue = (categoriesTiles) => categoriesTiles.length === 4 ? 6 : 4

const CategoriesNavButtons = () => {
  const location = useLocation()
  const categoriesTiles = getAllCategoriesForPage(location)

  return <Row className='categories-buttons d-flex justify-content-between align-items-center'>
    {categoriesTiles?.map(categoryTile =>
      <Col
        key={categoryTile.category}
        className={getCategoryButtonClassName(location, categoryTile.url, categoriesTiles)}
        xs={12}
        sm={6}
        lg={getLgGridValue(categoriesTiles)}
      >
        <Link
          to={categoryTile.url}
          className='font-30 d-flex justify-content-center align-items-center text-center'
        >
          {categoryTile.category}
        </Link>
      </Col>
    )}
  </Row>
}

export default CategoriesNavButtons

CategoriesNavButtons.propTypes = {
  categoriesTiles: PropTypes.arrayOf(CategoriesTilePropType),
}

CategoriesNavButtons.defaultProps = {
  categoriesTiles: [],
}
