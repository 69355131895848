import React from 'react'
import { Helmet } from 'react-helmet'
import logo from '../../images/logoBlack.svg'

const defaultDescription = 'Ensuring the safety and protection of life. We envision a safer future that is protected by uniformed services, local talent, locally based world-class technologies and innovations.'
const url = 'https://www.mestltd.com/'
const keywords = [
  'Eco Awareness',
  'Air',
  'Helicopter',
  'Aerial Systems & Solutions',
  'Aircraft support',
  'Ground',
  'Armored Vehicle Platforms',
  'Ammunition',
  'Equipment',
  'Artillery systems',
  'Air defence systems',
  'Armor Systems',
  'Launcher systems',
  'Military trucks',
  'Camouflage',
  'Weapon systems',
  'Missile Systems',
  'Logistic equipment',
  'Sea',
  'Vessels',
  'Underwater systems',
  'Weapon systems',
  'Amphibious armored vehicles',
  'Maritime traffic management',
  'Inertial systems',
  'Unmanned Systems',
  'Unmanned Aerial Systems',
  'Unmanned Ground Systems',
  'Unmanned Surface Systems',
  'Unmanned Underwater Systems',
  'Security',
  'Cyber security',
  'Public sector security',
  'Secure communications systems',
  'Electronics',
  'Electronic warfare',
  'Radar systems',
  'Communication systems',
  'C4ISR Systems',
  'Engineering and Modernization',
  'Special Forces Training',
  'Army Forces Training',
  'Cyber Security',
  'Hazardous material handling and disposal',
  'Munition disposal and recycling',
  'Hazardous Materials handling',
  'Land clearance']
const title = 'Mest Ltd'

const SEO = ({ description }) =>
  <Helmet
    htmlAttributes={{ lang: 'en', }}
    link={[{ rel: 'canonical', href: url, }]}
    title={title}
    meta={[
      {
        name: `description`,
        content: description ?? defaultDescription,
      },
      {
        name: 'keywords',
        content: keywords,
      },
      {
        property: `og:title`,
        content: title,
      },
      {
        property: `og:description`,
        content: description ?? defaultDescription,
      },
      {
        property: `og:type`,
        content: `website`,
      },
      {
        property: 'og:image',
        content: logo,
      },
      {
        property: 'og:image:width',
        content: 210,
      },
      {
        property: 'og:image:height',
        content: 50,
      },
    ]}
    charSet='utf-8'
  />

export default SEO
