import React from 'react'
import { Col } from 'react-bootstrap'
import { Link } from 'gatsby'
import PropTypes from 'prop-types'
import { FooterColPropType } from '../propTypes'

const FooterCol = ({ header, links }) =>
  <Col
    xs={12}
    md={3}
    className='d-flex flex-column'
  >
    <div className='footer-header font-20'>{header}</div>
    {links.map(link =>
      <Link
        to={link.href}
        key={link.label}
        className='footer-link font-montserrat font-20'
      >
        {link.label}
      </Link>
    )}
  </Col>

export default FooterCol

FooterCol.propTypes = {
  header: PropTypes.string.isRequired,
  links: PropTypes.arrayOf(FooterColPropType).isRequired,
}
