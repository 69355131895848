import React from 'react'
import MestButton from '../MestButton/MestButton'
import { ButtonLinkPropType, HeaderWithDescriptionPropType } from '../propTypes'

const ServiceTilesDetails = ({ headerWithDescription, button }) =>
  <div className='service-tiles-details d-block d-md-flex justify-content-between align-items-start'>
    <h2 className='service-tiles-details__header'>
      {headerWithDescription?.header}
    </h2>
    <div className='service-tiles-details__description-with-button d-flex flex-column justify-content-between'>
      <h5 className='font-montserrat'>{headerWithDescription?.description}</h5>
      <MestButton
        href={button?.href}
        label={button?.label}
      />
    </div>
  </div>

export default ServiceTilesDetails

ServiceTilesDetails.propTypes = {
  headerWithDescription: HeaderWithDescriptionPropType,
  button: ButtonLinkPropType
}

ServiceTilesDetails.defaultProps = {
  headerWithDescription: '',
  button: {}
}

