import React, { useRef } from 'react'
import { Col } from 'react-bootstrap'
import Slider from 'react-slick'
import SwappingTileContent from './SwappingTileContent'
import { swappingTileSettings } from '../../consts'
import PropTypes from 'prop-types'
import { CategoriesTilePropType } from '../../propTypes'
import { noop } from 'lodash'

const SwappingTile = ({ tiles, xs, md, specificProductOnClick, className, goToTile }) => {
  const sliderRef = useRef(null)
  if (sliderRef.current) {
    sliderRef.current.slickGoTo(goToTile)
  }

  return <Col
    className={className}
    xs={xs}
    md={md}
  >
    <Slider ref={sliderRef} {...swappingTileSettings}>
      {tiles.map(tile =>
        <SwappingTileContent
          specificProductOnClick={specificProductOnClick}
          {...tile}
          key={tile.header}
          buttonLabel='more'
        />
      )}
    </Slider>
  </Col>
}

export default SwappingTile

SwappingTile.propTypes = {
  tiles: PropTypes.arrayOf(CategoriesTilePropType),
  xs: PropTypes.number,
  md: PropTypes.number,
  specificProductOnClick: PropTypes.func,
  className: PropTypes.string,
  goToTile: PropTypes.number
}

SwappingTile.defaultProps = {
  tiles: [],
  xs: 12,
  specificProductOnClick: noop,
  className: '',
  goToTile: 0
}
