import React from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'

const getMestButtonClassName = (className, color) => classNames(
  className,
  'mest-button font-20 d-block text-center',
  {
    'mest-button-white': color === 'white'
  },
  {
    'mest-button-green': color === 'green'
  }
)

const MestButton = ({ className, href, label, color }) =>
  <Link
    to={href}
    className={getMestButtonClassName(className, color)}
  >
    {label}
  </Link>

export default MestButton

MestButton.propTypes = {
  className: PropTypes.string,
  href: PropTypes.string,
  label: PropTypes.string,
  color: PropTypes.string
}

MestButton.defaultProps = {
  className: '',
  href: '',
  label: 'more',
  color: 'green'
}
