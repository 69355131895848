import React from 'react'
import SectionWrapper from '../SectionWrapper/SectionWrapper'
import { Row } from 'react-bootstrap'
import { footerCols } from '../consts'
import FooterCol from './FooterCol'
import FooterColWithLogo from './FooterColWithLogo'

const Footer = () =>
  <SectionWrapper className='footer'>
    <Row className='d-flex justify-content-center align-items-start'>
      {footerCols.map(footerCol =>
        <FooterCol
          key={footerCol.header}
          {...footerCol}
        />)}
      <FooterColWithLogo/>
    </Row>
  </SectionWrapper>

export default Footer
