import React from 'react'
import { Col, Row } from 'react-bootstrap'
import Paragraph from '../Paragraph/Paragraph'
import { HeaderWithDescriptionPropType } from '../propTypes'
import PropTypes from 'prop-types'

const MestInBriefWithImage = ({ headerWithDescription, headline }) =>
  <Row
    className='mest-in-brief-with-image d-flex justify-content-center align-items-center'
    id='companyInBriefWithImage'
  >
    <Col
      xs={6}
      className='mest-in-brief-with-image__header-with-image justify-content-center d-none d-lg-flex'
    >
      <h2>
        {headerWithDescription?.header}
      </h2>
    </Col>
    <Col
      xs={12}
      lg={6}
      className='mest-in-brief-with-image__details'
    >
      <div>
        <h2 className='d-lg-none'>
          {headerWithDescription?.header}
        </h2>
        <h5 className='font-montserrat mest-in-brief-with-image-headline'>
          {headline}
        </h5>
        <Paragraph
          className='font-montserrat'
          size='h5'
          text={headerWithDescription?.description}
        />
      </div>
    </Col>
  </Row>

export default MestInBriefWithImage

MestInBriefWithImage.propTypes = {
  headerWithDescription: HeaderWithDescriptionPropType,
  headline: PropTypes.string,
}

MestInBriefWithImage.defaultProps = {
  headerWithDescription: {},
  headline: '',
}
