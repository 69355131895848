import React from 'react'
import AboutUsTile from './AboutUsTile'
import PropTypes from 'prop-types'
import { AboutUsTilePropType } from '../propTypes'

const RightTiles = ({ tiles }) =>
  <div className='about-us-tiles-gray-tiles d-flex flex-column align-items-end d-md-block'>
    {tiles.map(tile =>
      <AboutUsTile
        key={tile.header}
        {...tile}
      />
    )}
  </div>

export default RightTiles

RightTiles.propTypes = {
  tiles: PropTypes.arrayOf(AboutUsTilePropType)
}

RightTiles.defaultProps = {
  tiles: []
}
