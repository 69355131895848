import React from 'react'
import SectionWrapper from '../SectionWrapper/SectionWrapper'
import HeaderDetails from '../Header/HeaderDetails'
import PropTypes from 'prop-types'
import { ButtonLinkPropType, HeaderWithDescriptionPropType, StrapiImagePropType } from '../propTypes'
import classNames from 'classnames'
import SwappingBackground from './SwappingBackground'

const getHeaderClassName = (grayBackground, description) => classNames(
  { 'header-with-gray-background': grayBackground },
  { 'header-without-description': !description }
  , 'header'
)

const SwappingBackgroundHeader = ({ headerWithDescription, images, buttons, grayBackground }) =>
  <SectionWrapper className={getHeaderClassName(grayBackground, headerWithDescription?.description)}>
    <SwappingBackground images={images} />
    <HeaderDetails
      headerWithDescription={headerWithDescription}
      buttons={buttons}
    />
  </SectionWrapper>

export default SwappingBackgroundHeader

SwappingBackgroundHeader.propTypes = {
  headerWithDescription: HeaderWithDescriptionPropType,
  images: PropTypes.arrayOf(StrapiImagePropType),
  buttons: PropTypes.arrayOf(ButtonLinkPropType),
  grayBackground: PropTypes.bool
}

SwappingBackgroundHeader.defaultProps = {
  headerWithDescription: {},
  images: [],
  buttons: [],
  grayBackground: false
}
