import React from 'react'
import { Col } from 'react-bootstrap'
import MestButton from '../MestButton/MestButton'
import PropTypes from 'prop-types'
import { ButtonLinkPropType } from '../propTypes'
import Paragraph from '../Paragraph/Paragraph'

const AboutUsColumn = ({ header, headline, description, button }) =>
  <Col className='about-us-column'>
    {header && <h2 className='about-us-column__header'>{header}</h2>}
    {headline && <h3 className='about-us-column__headline'>{headline}</h3>}
    {description && <Paragraph
      className='about-us-column__description font-montserrat'
      text={description}
    />}
    {button && <MestButton className='about-us-column__button' {...button} />}
  </Col>

export default AboutUsColumn

AboutUsColumn.propTypes = {
  header: PropTypes.string,
  headline: PropTypes.string,
  description: PropTypes.string,
  button: ButtonLinkPropType
}
