import React, { useState } from 'react'
import { Col, Row } from 'react-bootstrap'
import PropTypes from 'prop-types'
import { StrapiImagePropType } from '../propTypes'
import { noop } from 'lodash'
import Paragraph from '../Paragraph/Paragraph'

const SpecificProduct = ({ header, description, image, onClose }) => {
  const [className, setClassName] = useState('specific-product')

  const closeOnClick = () => {
    setClassName('specific-product disappear')
    setTimeout(() => {
      onClose(null)
    }, 500)
  }

  return <Row
    className={className}
  >
    <Col
      xs={12}
      md={6}
      className='specific-product__image p-0'
    >
      <img
        width='640px'
        height='640px'
        src={image?.url}
        alt={image?.alternativeText}
      />
    </Col>
    <Col
      xs={12}
      md={6}
      className='specific-product__details d-flex justify-content-between flex-column p-0'
    >
      <div className='pb-5 pb-lg-0 w-100 d-flex justify-content-md-between align-items-center position-relative '>
        <h2>{header}</h2>
        <button onClick={() => closeOnClick()}>close</button>
      </div>
      <div className='specific-product__description'>
        <Paragraph
          className='font-montserrat'
          text={description}
        />
      </div>
    </Col>
  </Row>
}

export default SpecificProduct

SpecificProduct.propTypes = {
  header: PropTypes.string,
  description: PropTypes.string,
  image: StrapiImagePropType,
  onClose: PropTypes.func
}

SpecificProduct.defaultProps = {
  header: '',
  description: '',
  image: {},
  onClose: noop
}
