import React from 'react'

const AboutUsTilesBackground = () =>
  <div className='about-us-tiles-background d-flex justify-content-center align-items-end'>
    <div className='about-us-tiles-background__bar'/>
    <div className='about-us-tiles-background__bar'/>
    <div className='about-us-tiles-background__bar'/>
  </div>

export default AboutUsTilesBackground
