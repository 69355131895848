import React from 'react'
import { Col } from 'react-bootstrap'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import { StrapiImageWithLinesPropType } from '../propTypes'
import { Link } from 'gatsby'
import TileContent from './TileContent'

const getTileClassName = (className) => classNames(
  className,
  'tile default-shadow p-0'
)

const Tile = (props) =>
  <Col
    xs={12}
    sm={6}
    md={4}
    className={getTileClassName(props?.className)}
  >
    {props?.href
      ? <Link to={props?.href}>
        <TileContent{...props} />
      </Link>
      : <TileContent{...props} />
    }
  </Col>

export default Tile

Tile.propTypes = {
  header: PropTypes.string,
  description: PropTypes.string,
  image: StrapiImageWithLinesPropType,
  stripesBackground: PropTypes.bool,
  className: PropTypes.string,
  imageClassName: PropTypes.string,
  href: PropTypes.string
}

Tile.defaultProps = {
  header: '',
  description: '',
  image: {},
  stripesBackground: false,
  className: '',
  imageClassName: '',
  href: ''
}
