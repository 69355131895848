import PropTypes from 'prop-types'
import { noop } from 'lodash'

export const HeaderWithDescriptionPropType = PropTypes.shape({
  header: PropTypes.string,
  description: PropTypes.string
})

export const StrapiImagePropType = PropTypes.shape({
  alternativeText: PropTypes.string,
  url: PropTypes.string
})

export const ButtonLinkPropType = PropTypes.shape({
  label: PropTypes.string,
  href: PropTypes.string
})

export const FooterColPropType = PropTypes.shape({
  header: PropTypes.string,
  links: PropTypes.arrayOf(ButtonLinkPropType)
})

export const AboutUsTilePropType = PropTypes.shape({
  header: PropTypes.string,
  image: StrapiImagePropType,
  backgroundColor: PropTypes.string,
  grayTile: PropTypes.bool
})

export const AboutUsColumnPropType = PropTypes.shape({
  header: PropTypes.string,
  headline: PropTypes.string,
  description: PropTypes.string,
  button: ButtonLinkPropType
})

export const SpecificInformationPropType = PropTypes.shape({
  image: StrapiImagePropType,
  header: PropTypes.string,
  description: PropTypes.string
})

export const CategoriesTilePropType = PropTypes.shape({
  className: PropTypes.string,
  xs: PropTypes.number,
  image: StrapiImagePropType,
  header: PropTypes.string,
  button: ButtonLinkPropType,
  blankTile: PropTypes.bool,
  buttonOnTop: PropTypes.bool,
  specificProductOnClick: PropTypes.func,
  specificInformation: SpecificInformationPropType
})

export const TilePropTypes = {
  className: PropTypes.string,
  xs: PropTypes.number,
  md: PropTypes.number,
  image: StrapiImagePropType,
  header: PropTypes.string,
  buttonLabel: PropTypes.string,
  blankTile: PropTypes.bool,
  buttonOnTop: PropTypes.bool,
  specificProductOnClick: PropTypes.func,
  specificInformation: SpecificInformationPropType
}

export const TileDefaultProps = {
  className: '',
  xs: 12,
  image: {},
  header: '',
  buttonLabel: 'more',
  blankTile: false,
  buttonOnTop: false,
  specificProductOnClick: noop,
  specificInformation: {}
}

export const StrapiImageWithLinesPropType = PropTypes.shape({
  url: PropTypes.string,
  alternativeText: PropTypes.string,
  numberOfLines: PropTypes.number
})

export const TilePropType = PropTypes.shape({
  header: PropTypes.string,
  description: PropTypes.string,
  image: StrapiImageWithLinesPropType,
  numberOfLines: PropTypes.number
})




