import React from 'react'
import MestButton from '../MestButton/MestButton'
import { ButtonLinkPropType, HeaderWithDescriptionPropType } from '../propTypes'
import PropTypes from 'prop-types'

const HeaderDetails = ({ headerWithDescription, buttons }) =>
  <div className='header-details'>
    <h1 className='header-details__header'>
      {headerWithDescription?.header}
    </h1>
    <div className='header-details__body'>
      <h4 className='font-montserrat'>
        {headerWithDescription?.description}
      </h4>
      <div className='d-flex justify-content-start align-items-center'>
        {buttons.map(button =>
          <MestButton
            href={button?.href}
            label={button?.label}
          />
        )}
      </div>
    </div>
  </div>

export default HeaderDetails

HeaderDetails.propTypes = {
  headerWithDescription: HeaderWithDescriptionPropType,
  buttons: PropTypes.arrayOf(ButtonLinkPropType)
}

HeaderDetails.defaultProps = {
  headerWithDescription: {},
  buttons: []
}
