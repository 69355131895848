import React from 'react'
import classNames from 'classnames'
import whiteArrow from '../../../images/whiteArrow.svg'
import PropTypes from 'prop-types'
import { noop } from 'lodash'

const getButtonClassName = (className, buttonOnTop) => classNames(
  'category-tile__button font-14',
  {
    'category-tile__button font-20 category-tile__button--large': className === 'main-tile'
  },
  {
    'category-tile__button--on-top': buttonOnTop
  },
)

const TileButton = ({ className, buttonLabel, buttonOnTop, specificProductOnClick }) =>
  <button
    className={getButtonClassName(className, buttonOnTop)}
    onClick={specificProductOnClick}
  >
    {buttonLabel}
    <img
      src={whiteArrow}
      alt='Arrow'
    />
  </button>

export default TileButton

TileButton.propTypes = {
  className: PropTypes.string,
  buttonLabel: PropTypes.string,
  buttonOnTop: PropTypes.bool,
  specificProductOnClick: PropTypes.func
}

TileButton.defaultProps = {
  className: '',
  buttonLabel: 'more',
  buttonOnTop: false,
  specificProductOnClick: noop
}
