import React from 'react'
import { Link } from 'gatsby'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import NavBarSubLink from './NavBarSubLink'

const isBrowser = typeof window !== 'undefined'

const getNavBarLinkClassName = (active) => classNames(
  `nav-bar-link font-20 font-montserrat d-flex justify-content-center align-item-center flex-column`,
  { 'nav-bar-link-active': active }
)

const NavBarLink = ({ label, href, subLinks }) => {
  let active = false

  if (isBrowser) {
    if (href === window.location.pathname) {
      active = true
    }
  }

  return <div className={getNavBarLinkClassName(active)}>
    <Link
      key={href}
      to={href}
    >
      <div className='main-link'>
        {label}
      </div>
    </Link>
    {subLinks &&
      <div className='nav-bar-sub-links justify-content-center align-items-center align-items-md-center flex-row flex-md-column flex-nowrap'>
        {subLinks?.map((subLink, index) =>
          <NavBarSubLink key={index} {...subLink} />
        )}
      </div>}
  </div>

}

export default NavBarLink

NavBarLink.propTypes = {
  label: PropTypes.string,
  href: PropTypes.string
}

NavBarLink.defaultProps = {
  label: 'Link',
  href: '/'
}
