import React from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'

const getHeaderClassName = (buttonOnTop) =>
  classNames(
    'category-tile__header',
    {
      'category-tile__header--on-bottom': buttonOnTop
    }
  )

const TileHeader = ({ className, header, buttonOnTop }) =>
  className === 'main-tile'
    ?
    <h5 className='category-tile__header category-tile__header--large'>
      {header}
    </h5>
    :
    <h6 className={getHeaderClassName(buttonOnTop)}>
      {header}
    </h6>

export default TileHeader

TileHeader.propTypes = {
  className: PropTypes.string,
  header: PropTypes.string,
  buttonOnTop: PropTypes.bool
}

TileHeader.defaultProps = {
  className: '',
  header: '',
  buttonOnTop: false
}
