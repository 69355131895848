import React from 'react'
import SectionWrapper from '../SectionWrapper/SectionWrapper'
import ServiceTilesDetails from './ServiceTilesDetails'
import { serviceTiles } from '../consts'
import { ButtonLinkPropType, HeaderWithDescriptionPropType } from '../propTypes'
import PropTypes from 'prop-types'
import Tiles from '../Tiles/Tiles'

const ServiceTiles = ({ headerWithDescription, button, tiles }) => {
  tiles = tiles?.map((tile, index) => ({ ...tile, ...serviceTiles[index] }))

  return <SectionWrapper
    className='service-tiles'
    id='serviceTiles'
  >
    <div className='service-tiles-top-green-bar' />
    <ServiceTilesDetails
      headerWithDescription={headerWithDescription}
      button={button}
    />
    <Tiles
      tiles={tiles}
      tileClassName='service-tile'
      imageClassName='left-right-indentation'
    />
  </SectionWrapper>
}

export default ServiceTiles

ServiceTiles.propTypes = {
  headerWithDescription: HeaderWithDescriptionPropType,
  tiles: PropTypes.arrayOf(HeaderWithDescriptionPropType),
  button: ButtonLinkPropType
}

ServiceTiles.defaultProps = {
  headerWithDescription: '',
  tiles: [],
  button: {}
}
