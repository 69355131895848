import React from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import Media from './Media'
import { StrapiImagePropType } from '../propTypes'

const getImageWithLinesClassName = (className, withGradient) => classNames(
  className,
  'image-with-lines',
  { 'image-with-lines-and-gradient': withGradient }
)

const MediaWithLines = ({ className, numberOfLines, media, withGradient, poster }) =>
  <div className={getImageWithLinesClassName(className, withGradient)}>
    <Media {...media} poster={poster} />
    <div className='image-with-lines__lines d-flex justify-content-center align-content-center'>
      {[...Array(numberOfLines + 1)].map((elem, index) =>
        <div
          key={index}
          className='image-with-lines-line'
        />
      )}
    </div>
  </div>

export default MediaWithLines

MediaWithLines.propTypes = {
  className: PropTypes.string,
  numberOfLines: PropTypes.number,
  media: StrapiImagePropType,
  alternativeText: PropTypes.string,
  withGradient: PropTypes.bool,
  poster: StrapiImagePropType
}

MediaWithLines.defaultProps = {
  className: '',
  numberOfLines: 0,
  alternativeText: '',
  withGradient: false
}
