import React, { useState } from 'react'
import SectionWrapper from '../SectionWrapper/SectionWrapper'
import { Nav, Navbar } from 'react-bootstrap'
import { navBarLinks } from '../consts'
import NavBarLink from './NavBarLink'
import { Link } from 'gatsby'
import logo from '../../images/mest-logo.svg'
import classnames from 'classnames'

const getNavBarClassName = (isCollapsed) => classnames(
  { 'nav-bar-collapsed': isCollapsed }, 'nav-bar',
)

const NavBar = () => {
  const [isCollapsed, setIsCollapsed] = useState(false)

  return <SectionWrapper className={getNavBarClassName(isCollapsed)}>
    <Navbar
      variant='dark'
      expand='md'
      className='p-0 align-items-start'
    >
      <Link to='/'>
        <img
          className='nav-bar__logo'
          src={logo}
          alt='Mest'
        />
      </Link>

      <Navbar.Toggle
        aria-label='Toggle navigation'
        aria-controls='navbarSupportedContent'
        aria-expanded='false'
        data-toggle='collapse'
        type='button'
        onClick={() => setIsCollapsed(!isCollapsed)}
      />
      <Navbar.Collapse className='justify-content-end'>
        <Nav className='nav-bar-links align-items-start'>
          {navBarLinks.map(link =>
            <NavBarLink key={link.href} {...link} />
          )}
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  </SectionWrapper>
}
export default NavBar
