import React from 'react'
import blankTile from '../../../images/categories/blankTile.png'
import whiteArrow from '../../../images/whiteArrow.svg'
import PropTypes from 'prop-types'

const BlankTileContent = ({ label }) =>
  <>
    <img
      src={blankTile}
      alt='Blank'
    />
    <div className='blank-category-tile__button d-flex justify-content-center align-items-center'>
      <img
        src={whiteArrow}
        alt='Arrow'
      />
      {label}
    </div>
  </>

export default BlankTileContent

BlankTileContent.propTypes = {
  label: PropTypes.string
}

BlankTileContent.defaultProps = {
  label: ''
}
