import React from 'react'
import { Row } from 'react-bootstrap'
import { homePageTilesImages } from '../consts'
import SectionWrapper from '../SectionWrapper/SectionWrapper'
import PropTypes from 'prop-types'
import { HeaderWithDescriptionPropType } from '../propTypes'
import Tiles from '../Tiles/Tiles'

const HomePageTiles = ({ tiles }) => {
  tiles = tiles.map((tile, index) => ({ ...tile, ...homePageTilesImages[index] }))

  return <SectionWrapper className='home-page-tiles'>
    <Row className='d-flex justify-content-between align-items-center'>
      <Tiles
        tiles={tiles}
        tileClassName='home-page-tile'
        imageClassName='home-page-tile__image'
      />
    </Row>
  </SectionWrapper>
}

export default HomePageTiles

HomePageTiles.propTypes = {
  tiles: PropTypes.arrayOf(HeaderWithDescriptionPropType),
}

HomePageTiles.defaultProps = {
  tiles: ''
}
