import React from 'react'
import { Col } from 'react-bootstrap'
import classNames from 'classnames'
import TileHeader from './TileHeader'
import TileButton from './TileButton'
import BlankTile from './BlankTile'
import { TileDefaultProps, TilePropTypes } from '../../propTypes'

const getCategoryTileClassName = (className) => classNames(
  className,
  'category-tile p-0'
)

const setSpecificInformation = (specificInformation, image) => ({
  ...specificInformation,
  image: image
})

const Tile = ({
  className,
  xs,
  md,
  image,
  header,
  buttonLabel,
  blankTile,
  buttonOnTop,
  specificProductOnClick,
  specificInformation
}) =>
  blankTile ?
    <BlankTile
      xs={xs}
      md={md}
    />
    : <Col
      onClick={() => specificProductOnClick(setSpecificInformation(specificInformation, image))}
      className={getCategoryTileClassName(className)}
      xs={xs}
      md={md}
    >
      <img
        src={image?.url}
        alt={image?.alternativeText}
      />
      <TileHeader
        className={className}
        header={header}
        buttonOnTop={buttonOnTop}
      />
      {buttonLabel && <TileButton
        className={className}
        buttonLabel={buttonLabel}
        buttonOnTop={buttonOnTop}
      />}
    </Col>

export default Tile

Tile.propTypes = TilePropTypes

Tile.defaultProps = TileDefaultProps
