import React from 'react'
import { Col } from 'react-bootstrap'
import mestLogo from '../../images/mestLogoWhite.svg'
import PropTypes from 'prop-types'

const MestInBriefWithLogoHeader = ({ header }) =>
  <Col
    xs={12}
    md={6}
    className='mest-in-brief-with-logo__header d-flex justify-content-between flex-column'
  >
    <h2>{header}</h2>
    <img
      src={mestLogo}
      alt='Mest'
    />
  </Col>

export default MestInBriefWithLogoHeader

MestInBriefWithLogoHeader.propTypes = {
  headline: PropTypes.string,
}

MestInBriefWithLogoHeader.defaultProps = {
  headline: '',
}
