import React from 'react'
import { Col } from 'react-bootstrap'
import Paragraph from '../Paragraph/Paragraph'
import MestButton from '../MestButton/MestButton'
import PropTypes from 'prop-types'

const MestInBriefWithLogoDetails = ({ headline, description }) =>
  <Col
    xs={12}
    md={6}
    className='mest-in-brief-with-logo-details'
  >
    <h5 className='mest-in-brief-with-logo-details__headline font-bold font-montserrat'>{headline}</h5>
    <Paragraph
      className='font-montserrat'
      text={description}
    />
    <MestButton
      className='mest-in-brief-with-logo-details__button'
      color='white'
      href='/'
    />
  </Col>

export default MestInBriefWithLogoDetails

MestInBriefWithLogoDetails.propTypes = {
  headline: PropTypes.string,
  description: PropTypes.string
}

MestInBriefWithLogoDetails.defaultProps = {
  headline: '',
  description: ''
}
