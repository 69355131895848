import cuttingEdgeTechnology from '../images/homePageTiles/cuttingEdgeTechnology.webp'
import fieldTestedProducts from '../images/homePageTiles/fieldTestedProducts.webp'
import unparalleledServices from '../images/homePageTiles/unparalleledServices.webp'
import airship from '../images/serviceTiles/airship.webp'
import rosomak from '../images/serviceTiles/rosomak.webp'
import aircraftCarrier from '../images/serviceTiles/aircraftCarrier.webp'
import UASService from '../images/serviceTiles/unmannedGroundSystems.webp'
import securitySystems from '../images/serviceTiles/securitySystems.webp'
import electronicWarfare from '../images/serviceTiles/electronicWarfare.webp'
import helicopter from '../images/categories/air/helicopter.webp'
import aerialSystemSolutions from '../images/categories/air/aerialSystemSolutions.webp'
import aircraftSupport from '../images/categories/air/aircraftSupport.webp'
import armoredVehiclePlatforms from '../images/categories/ground/armoredVehicelPlatforms.webp'
import airDefenceSystems from '../images/categories/ground/airDefenceSystems.webp'
import militaryTrucks from '../images/categories/ground/militaryTrucks.webp'
import weaponSystems from '../images/categories/ground/weaponSystems.webp'
import ammunition from '../images/categories/ground/ammunition.webp'
import launcherSystems from '../images/categories/ground/launcherSystems.webp'
import armorSystems from '../images/categories/ground/armorSystems.webp'
import artillerySystems from '../images/categories/ground/artillerySystems.webp'
import missileSystems from '../images/categories/ground/missileSystems.webp'
import equipment from '../images/categories/ground/equipment.webp'
import logisticsEquipment from '../images/categories/ground/logisticsEquipment.webp'
import camouflage from '../images/categories/ground/camouflage.webp'
import vessels from '../images/categories/sea/vessels.webp'
import underwaterSystems from '../images/categories/sea/underwaterSystems.webp'
import seaWeaponSystems from '../images/categories/sea/weaponSystems.webp'
import amphibiousArmoredVehicles from '../images/categories/sea/amphibiousArmoredVehicles.webp'
import maritimeTrafficManagement from '../images/categories/sea/maritimeTrafficManagement.webp'
import inertialSystems from '../images/categories/sea/inertialSystems.webp'
import unmannedGroundSystems from '../images/categories/uas/unmannedGroundSystems.webp'
import unmannedAerialSystems from '../images/categories/uas/unmannedAerialSystems.webp'
import unmannedSurfaceSystems from '../images/categories/uas/unmannedSurfaceSystems.webp'
import unmannedUnderwaterSystems from '../images/categories/uas/unmannedUnderwaterSystems.webp'
import cyberSecurity from '../images/categories/security/cyberSecurity.webp'
import publicSectorSecurity from '../images/categories/security/publicSectorSecurity.webp'
import secureCommunicationSystems from '../images/categories/security/secureCommunicationSystems.webp'
import electronicWarfare2 from '../images/categories/electronics/electronicWarefare.webp'
import radars from '../images/categories/electronics/radars.webp'
import C4ISystems from '../images/categories/electronics/C4ISystems.webp'
import communicationSystems from '../images/categories/electronics/communicationSystems.webp'
import specialForcesTraining from '../images/categories/training/specialForcesTraining.webp'
import armyForcesTraining from '../images/categories/training/armyForcesTraining.webp'
import cyberSecurity2 from '../images/categories/training/cyberSecurity.webp'
import munitionDisposalAndRecycling
  from '../images/categories/hazardousMaterialHandlingAndDisposal/munitionDisposalAndRecycling.webp'
import solutionsForHazardousMaterialsHandling
  from '../images/categories/hazardousMaterialHandlingAndDisposal/solutionsForHazardousMaterialsHandling.webp'
import courseForLandClearanceOfUnexplodedOrdnance
  from '../images/categories/hazardousMaterialHandlingAndDisposal/courseForLandClearanceOfUnexplodedOrdnance.webp'
import network from '../images/workWithUs/network.webp'
import arrow from '../images/workWithUs/arrow.webp'
import magnifier from '../images/workWithUs/magnifier.webp'

export const videoMimeTypes = ['video/x-flv', 'video/mp4', 'video/webm', 'video/quicktime', 'video/x-msvideo', 'video/x-ms-wmv']

export const navBarLinks = [
  {
    label: 'Who we are',
    href: '/who-we-are',
    subLinks: [
      {
        label: 'Eco awareness',
        href: '/eco-awareness'
      }
    ]
  },
  {
    label: 'What we offer',
    href: '/what-we-offer',
    subLinks: [
      {
        label: 'Air',
        href: '/what-we-offer/air'
      }, {
        label: 'Ground',
        href: '/what-we-offer/ground'
      }, {
        label: 'Sea',
        href: '/what-we-offer/sea'
      }, {
        label: 'Unmanned Systems',
        href: '/what-we-offer/unmanned-systems'
      }, {
        label: 'Security',
        href: '/what-we-offer/security'
      }, {
        label: 'Electronics',
        href: '/what-we-offer/electronics'
      },
    ]
  },
  {
    label: 'Connect with us',
    href: '/connect-with-us',
  }
]

export const footerCols = [
  {
    header: 'Company',
    links: [
      {
        label: 'Vision',
        href: '/who-we-are#aboutUs'
      },
      {
        label: 'Mission',
        href: '/who-we-are#aboutUs'
      },
      {
        label: 'Company in brief',
        href: '/who-we-are#companyInBriefWithImage'
      },
    ]
  },
  {
    header: 'What we offer',
    links: [
      {
        label: 'Solutions',
        href: '/what-we-offer#serviceTiles'
      },
      {
        label: 'Services',
        href: '/what-we-offer#aboutUsTiles'
      },
    ]
  },
  {
    header: 'Connect with us',
    links: [{
      label: 'Contact',
      href: '/connect-with-us'
    }]
  },
]

export const aboutUsTiles = [
  {
    backgroundColor: 'green',
    position: 'left',
    href: '/what-we-offer/hazardous-material-handling-and-disposal'
  },
  {
    backgroundColor: 'white',
    position: 'left',
    href: '/what-we-offer/simulation-systems'
  },
  {
    backgroundColor: 'gray',
    position: 'right',
    href: '/what-we-offer/engineering-and-modernization'
  },
  {
    backgroundColor: 'dark-gray',
    position: 'right',
    href: '/what-we-offer/training'
  },
]

export const workWithUsTiles = [
  {
    image: {
      height: '42px',
      width: '42px',
      url: network,
      alternativeText: ''
    }
  },
  {
    image: {
      height: '42px',
      width: '42px',
      url: magnifier,
      alternativeText: ''
    }
  },
  {
    image: {
      height: '42px',
      width: '42px',
      url: arrow,
      alternativeText: ''
    }
  },
]

export const homePageTilesImages = [
  {
    image: {
      url: cuttingEdgeTechnology,
      alternativeText: 'Cutting edge technology',
      numberOfLines: 1,
    }
  },
  {
    image: {
      url: fieldTestedProducts,
      alternativeText: 'Field tested products',
      numberOfLines: 2,
    }
  },
  {
    image: {
      url: unparalleledServices,
      alternativeText: 'Unparalleled services',
      numberOfLines: 1,
    }
  }
]

export const serviceTiles = [
  {
    href: '/what-we-offer/air',
    image: {
      url: airship,
      alternativeText: 'Airship',
      numberOfLines: 2
    },
  }, {
    href: '/what-we-offer/ground',
    image: {
      url: rosomak,
      alternativeText: 'Desert',
      numberOfLines: 2
    },
  }, {
    href: '/what-we-offer/sea',
    image: {
      url: aircraftCarrier,
      alternativeText: 'Aircraft carrier',
      numberOfLines: 2
    },
  }, {
    href: '/what-we-offer/unmanned-systems',
    image: {
      url: UASService,
      alternativeText: 'Armor systems',
      numberOfLines: 2
    },
  }, {
    href: '/what-we-offer/security',
    image: {
      url: securitySystems,
      alternativeText: 'Security systems',
      numberOfLines: 2
    },
  }, {
    href: '/what-we-offer/electronics',
    image: {
      url: electronicWarfare,
      alternativeText: 'Electronic Warfare',
      numberOfLines: 2
    },
  },
]

export const categoriesTiles = [
  {
    category: 'Air',
    url: '/what-we-offer/air/',
    mainTile:
      {
        header: 'Helicopters',
        image: {
          url: helicopter,
          alternativeText: 'Helicopter'
        },
        button: {
          href: '/',
          label: 'more'
        },
        specificInformation: {
          image: {
            url: helicopter,
            alternativeText: 'Helicopter'
          },
          header: 'Helicopters',
          description: 'Providing tactical ground and air support in a wide variety of unforgiving terrains, our pioneering helicopters combine innovative technologies with unequaled performance and safety features. Front-line avionics and missions’ systems detect, identify, and attack Land, Sea and Air targets while safeguarding crew and enabling them to complete up to the minute tactical assessments in a variety of hostile situations'
        }
      },
    topTiles: [
      {
        header: 'Aerial Systems & Solutions',
        image: {
          url: aerialSystemSolutions,
          alternativeText: 'Aerial systems solutions'
        },
        specificInformation: {
          header: 'Aerial Systems & Solutions',
          description: 'We deliver Aerial Systems for reconnaissance, surveillance, target acquisition and air support, leveraging the latest technology to aid professionals in their mission.\n' +
            'Our systems include EO/IR, warning and countermeasures, electronic warfare, as well as weaponry leveraging the latest technology to give command centers air superiority and greater situational awareness in critical moments on the connected battlefield, ensuring mission success. \n'
        },
        button: {
          href: '/',
          label: 'more'
        },
      },
      {
        buttonOnTop: true,
        header: 'Aircraft support',
        image: {
          url: aircraftSupport,
          alternativeText: 'Aircraft support'
        },
        specificInformation: {
          header: 'Aircraft support',
          description: 'Giving your personnel the support and maintenance required for your fleet of manned and unmanned aircraft, ensuring you are always ready to respond and protect.'
        },
        button: {
          href: '/',
          label: 'more'
        },
      },
      {
        blankTile: true,
      }
    ],
  },
  {
    category: 'Ground',
    url: '/what-we-offer/ground/',
    swappingTopTiles: true,
    mainTiles: [
      {
        header: 'Armored Vehicle Platforms',
        image: {
          url: armoredVehiclePlatforms,
          alternativeText: 'Armored vehicle platforms'
        },
        button: {
          href: '/',
          label: 'more'
        },
        specificInformation: {
          header: 'Armored vehicle platforms',
          description: 'We provide combat-proven, high-performance tracked and wheeled modular multi-role armored vehicles designed for a wide variety of missions in all terrains, offering exceptional protection (STANAG), mobility, and firepower for mission success. An amphibious capability is offered for water crossing and landing tasks.\n' +
            'Available as: APC, IFV, Command-Post, Medic, Recovery, Reconnaissance, Maintenance, and customized versions.'
        }
      },
      {
        header: 'Ammunition',
        image: {
          url: ammunition,
          alternativeText: 'Ammunition'
        },
        specificInformation: {
          header: 'Ammunition',
          description: 'Whether it’s small, medium or large caliber; armor-piercing, blank or multi-purpose, our extensive range of ammunition will provide you with the very best capabilities in target neutralization.'
        },
      },
      {
        buttonOnTop: true,
        header: 'Equipment',
        image: {
          url: equipment,
          alternativeText: 'Equipment'
        },
        specificInformation: {
          header: 'Equipment',
          description: 'Ensure personnel are equipped with the most up-to-date equipment to enable them to effectively complete their missions. Our full range of protective clothing and combat gear gives the modern soldier comfort and support.'
        },
      }
    ],
    topTiles: [
      [{
        header: 'Artillery systems',
        image: {
          url: artillerySystems,
          alternativeText: 'Aerial systems solutions'
        },
        specificInformation: {
          header: 'Artillery systems',
          description: 'We offer a variety of self-propelled howitzers that can be towed, wheeled, or tracked, as well as mortar and recoiling mortar systems with unequaled firepower, advanced fire control systems and computers. We also provide inertial navigation pointing, which improves system response time, lethality, and accuracy.'
        },
        button: {
          href: '/',
          label: 'more'
        },
      },
        {
          buttonOnTop: true,
          header: 'Air defence systems',
          image: {
            url: airDefenceSystems,
            alternativeText: 'Air defence systems'
          },
          specificInformation: {
            header: 'Air defence systems',
            description: 'Leading-edge air and missile defense system meant to keep planes, helicopters, unmanned aerial vehicles (UAVs), tactical and short-range ballistic missiles, and cruise missiles away from maneuver forces, cities and vital structures.'
          },
        },
        {
          header: 'Armor Systems',
          image: {
            url: armorSystems,
            alternativeText: 'Armor Systems'
          },
          specificInformation: {
            header: 'Armor Systems',
            description: '· Armor Systems\n' +
              '\n' +
              'Our service provides reactive, passive and transparent armor solutions giving your forces and their vehicles first-class, battle proven ballistic protection while still guaranteeing maneuverability in a wide range of missions.\n' +
              '\n' +
              '· Test and validation of armor systems\n' +
              '\n' +
              'We perform tests, validate, and certify armored systems and ballistic equipment with NATO Standardization Agreement (STANAG Standards). These cover a broad range of specified and unique risks, threats, and environments.'
          },
        }
      ],
      [
        {
          buttonOnTop: true,
          header: 'Launcher systems',
          image: {
            url: launcherSystems,
            alternativeText: 'Launcher systems'
          },
          specificInformation: {
            header: 'Launcher systems',
            description: 'Our array of launcher systems delivers pinpoint accuracy, letting your soldiers engage and suppress key area targets up to 500 kilometers behind enemy lines. Troop concentrations, command centers, support facilities, airfields, air defense and artillery batteries are all potential targets. Our platforms are customized and affordable support in land-based operations.'
          },
        },
        {
          header: 'Military trucks',
          image: {
            url: militaryTrucks,
            alternativeText: 'Military trucks'
          },
          specificInformation: {
            header: 'Military trucks',
            description: 'We offer a range of reliable, all-terrain transport for supplies, kit and personnel, combining protection and maneuverability in a variety of hostile circumstances.'
          },
        },
        {
          header: 'Camouflage',
          image: {
            url: camouflage,
            alternativeText: 'Camouflage'
          },
          specificInformation: {
            header: 'Camouflage',
            description: 'We offer advanced camouflage systems.\n' +
              'A range of solutions for infantry and vehicles. Depending on the needs, we are able to provide a camouflage adjusted for advanced sensors.'
          },
        }
      ],
      [
        {
          buttonOnTop: true,
          header: 'Weapon systems',
          image: {
            url: weaponSystems,
            alternativeText: 'Weapon systems'
          },
          specificInformation: {
            header: 'Weapon systems',
            description: 'Allowing for multiple, mission-tailored configurations, our weapons systems can provide ambidextrous and ergonomic modules giving the user light support weapons, assault rifles, carbines, grenade launcher and marksman rifle capabilities.'
          },
        },
        {
          header: 'Missile Systems',
          image: {
            url: missileSystems,
            alternativeText: 'Missile Systems'
          },
          specificInformation: {
            header: 'Missile Systems',
            description: `Systems equipped with cutting-edge sensor and guidance systems to address complex scenarios, detect and prevent countermeasures deployed by the target, enabling longer-range engagement and threat suppression.
            Our offer includes the following:
            - UAV counter-systems
            - Men-portable anti-air defense systems, MANPADS
            - Guided anti-tank systems
            - Anti-ship systems`
          },
        },
        {
          buttonOnTop: true,
          header: 'Logistic equipment',
          image: {
            url: logisticsEquipment,
            alternativeText: 'Logistic equipment'
          },
          specificInformation: {
            header: 'Logistic equipment',
            description: 'A full range of equipment giving command centers the control and planning ability they require for successful operations.'
          },
        }
      ]
    ],
  },
  {
    category: 'Sea',
    url: '/what-we-offer/sea/',
    mainTile:
      {
        header: 'Vessels',
        image: {
          url: vessels,
          alternativeText: 'Vessels'
        },
        specificInformation: {
          image: {
            url: helicopter,
            alternativeText: 'Vessels'
          },
          header: 'Vessels',
          description: 'We provide state-of-the-art vessels for coastal, riverine, inshore, and offshore missions. Our platforms assist you in protecting your coastal territories from threats and unlawful activity. We supply vessels that have unrivaled seakeeping abilities and can combine a high-speed and long-range capability, offering endurance and high performance in any situation.'
        }
      },
    topTiles: [
      {
        header: 'Underwater systems',
        image: {
          url: underwaterSystems,
          alternativeText: 'Underwater systems'
        },
        specificInformation: {
          header: 'Underwater systems',
          description: 'We supply a wide range of unmanned, high performance underwater systems enabling you to detect and intercept threats planning to attack from the sea.'
        },
      },
      {
        buttonOnTop: true,
        header: 'Weapon systems',
        image: {
          url: seaWeaponSystems,
          alternativeText: 'Weapon systems'
        },
        specificInformation: {
          header: 'Weapon systems',
          description: 'We offer multiple systems giving your forces the ability to create and maintain superiority in the water.'
        },
      },
      {
        header: 'Amphibious armored vehicles',
        image: {
          url: amphibiousArmoredVehicles,
          alternativeText: 'Amphibious armored vehicles'
        },
        specificInformation: {
          header: 'Amphibious armored vehicles',
          description: 'We provide amphibious armored vehicles to defend personnel in the harshest environments and situations, as well as support littoral operations in open water to complete mission objectives. Our amphibious platforms provide mobility, superior protection, and firepower, with increased payload capacity and cutting-edge technologies to satisfy the needs of our clients. \n'
        },
      },
      {
        buttonOnTop: true,
        header: 'Maritime traffic management',
        image: {
          url: maritimeTrafficManagement,
          alternativeText: 'Maritime traffic management'
        },
        specificInformation: {
          header: 'Maritime traffic management',
          description: 'Giving your command centers the dexterity and information required to manage all of your maritime traffic, ensuring safety and efficiency.'
        },
      },

    ],
    bottomTiles: [
      {
        header: 'Inertial systems',
        image: {
          url: inertialSystems,
          alternativeText: 'Inertial systems'
        },
        specificInformation: {
          header: 'Inertial systems',
          description: 'Ensure your personnel are able to monitor and co-ordinate their own movements, as well as locate and intercept threats from land, sea and air using our cutting-edge sensor technology.'
        },
      },
      {
        blankTile: true
      }
    ]
  },
  {
    category: 'Unmanned Systems',
    url: '/what-we-offer/unmanned-systems/',
    mainTile:
      {
        header: 'Unmanned Aerial Systems',
        image: {
          url: unmannedAerialSystems,
          alternativeText: 'Unmanned Aerial Systems'
        },
        specificInformation: {
          header: 'Vessels',
          description: 'Giving you surveillance, navigation, target location, and anti-jamming capabilities, our Unmanned Aerial Systems are unmatched in aiding your personnel to detect and destroy threats. Top of the range sensor systems give your assets the electronic support and information required for protection in modern-day security operations'
        }
      },
    topTiles: [
      {
        header: 'Unmanned Ground Systems',
        image: {
          url: unmannedGroundSystems,
          alternativeText: 'Unmanned Ground Systems'
        },
        specificInformation: {
          header: 'Unmanned Ground Systems',
          description: 'From providing support to intelligence operations to taking on ordinance disposal to transporting cargo and positioning weaponry, our unmanned vehicles perform a wide range of functions to provide support to troops and command centers on the battlefield and hostile urban environments.'
        },
      },
      {
        buttonOnTop: true,
        header: 'Unmanned Surface Systems',
        image: {
          url: unmannedSurfaceSystems,
          alternativeText: 'Unmanned Surface Systems'
        },
        specificInformation: {
          header: 'Unmanned Surface Systems',
          description: 'Giving your Naval units an additional layer to their defense capabilities, our Unmanned Surface Systems provide full horizon coverage with technologies to detect long-range threats, enhance real-time awareness and intercept enemy signals and mines. Our multi-functional solutions increase survivability and mission success in a variety of hostile naval environments.'
        },
      },
      {
        buttonOnTop: true,
        header: 'Unmanned Underwater Systems',
        image: {
          url: unmannedUnderwaterSystems,
          alternativeText: 'Unmanned Underwater Systems'
        },
        specificInformation: {
          header: 'Unmanned Underwater Systems',
          description: 'Allowing your naval units to create early detection warnings, analysis and protection from anti-ship missiles, our Unmanned Underwater Systems are your reliable first line in defense for your naval units.'
        },
      },
      {
        blankTile: true
      }

    ]
  },
  {
    category: 'Security',
    url: '/what-we-offer/security/',
    mainTile:
      {
        header: 'Cyber security',
        image: {
          url: cyberSecurity,
          alternativeText: 'Cyber security'
        },
        specificInformation: {
          header: 'Cyber security',
          description: 'Protect your data and information systems from cyber-attack with our security systems designed to detect and respond to threats, ensure against data loss and provide encryption. State-of-the-art and upgradable, our products will ensure you are protected from the most sophisticated forms of cyber threat.'
        },
      },
    topTiles: [
      {
        header: 'Public sector security',
        image: {
          url: publicSectorSecurity,
          alternativeText: 'Public sector security'
        },
        specificInformation: {
          header: 'Public sector security',
          description: 'Our systems provide civilians, companies and private assets with protection from all threat levels in this unpredictable world.'
        },
      },
      {
        buttonOnTop: true,
        header: 'Secure communications systems',
        image: {
          url: secureCommunicationSystems,
          alternativeText: 'Secure communications systems'
        },
        specificInformation: {
          header: 'Secure communications systems',
          description: 'End-to-end, fully encrypted communication systems allowing all personnel to maintain the contact and communication required to maximize their functional needs.'
        },
      },
      {
        blankTile: true
      }
    ]
  },
  {
    category: 'Electronics',
    url: '/what-we-offer/electronics/',
    mainTile:
      {
        header: 'Electronic warfare',
        image: {
          url: electronicWarfare2,
          alternativeText: 'Electronic warfare'
        },
        specificInformation: {
          header: 'Electronic warfare',
          description: 'Warning systems coupled with cutting-edge sensors, multi-spectral situational awareness, and countermeasure capabilities are among the solutions we offer to assure electromagnetic spectrum dominance and mission success. Our clients will be able to detect, protect, and communicate over the full spectrum, as well as jam the adversary’s radio, infrared, or radar communications.'
        },
      },
    topTiles: [
      {
        header: 'Radar systems',
        image: {
          url: radars,
          alternativeText: 'Radar systems'
        },
        specificInformation: {
          header: 'Radar systems',
          description: 'Early detection of threats is vital. Our radar systems allow personnel to protect themselves from distant airborne, naval and land-based threats and quickly engage with countermeasures to ensure the security of personnel and assets. In addition, radar systems can be used to monitor your own air and vehicular movements.'
        },
      },
      {
        header: 'Communication systems',
        image: {
          url: communicationSystems,
          alternativeText: 'Communication systems'
        },
        specificInformation: {
          header: 'Communication systems',
          description: 'Our first-rate technology provides battlefield warfighters with effective information workflows and relevant decision support which can make the difference between life and death. Superior tactical advantages and an operational edge are a given when using our solutions, which provide a seamless, integrated, robust, and secure communication chain.'
        },
      },
      {
        buttonOnTop: true,
        header: 'C4ISR Systems',
        image: {
          url: C4ISystems,
          alternativeText: 'C4ISR Systems'
        },
        specificInformation: {
          header: 'C4ISR Systems',
          description: 'Our end-to-end, interoperable systems gives your personnel the secure communications required in modern-day defense and rapidly-changing hostile situations. We supply the infrastructure and software required to maintain fully-integrated intelligence between all units in land, naval, and air operations.'
        },
      },
      {
        blankTile: true
      }
    ]
  }
]

export const serviceCategoriesTiles = [
  {
    category: 'Engineering and Modernization',
    url: '/what-we-offer/engineering-and-modernization/'
  },
  {
    category: 'Simulation systems',
    url: '/what-we-offer/simulation-systems/'
  },
  {
    category: 'Training',
    url: '/what-we-offer/training/',
    mainTile:
      {
        header: 'Special Forces Training',
        image: {
          url: specialForcesTraining,
          alternativeText: 'Special Forces Training'
        },
        specificInformation: {
          header: 'Special Forces Training',
          description: 'Offering training tailored for the modern-day elite forces, giving them the knowledge, combat skills and abilities they need to effectively assess, intercept, manage and terminate a wide variety of threats, as well as gather information and provide protection for assets.'
        },
      },
    topTiles: [
      {
        header: 'Army Forces Training',
        image: {
          url: armyForcesTraining,
          alternativeText: 'Army Forces Training'
        },
        specificInformation: {
          header: 'Army Forces Training',
          description: 'We provide all of your training needs to ensure your personnel and can confidently manage a wide-variety of threats levels, as well as learn fieldcraft in a variety of terrains, as well as first aid and weapon-handling skills.'
        },
      },
      {
        buttonOnTop: true,
        header: 'Cyber Security',
        image: {
          url: cyberSecurity2,
          alternativeText: 'Cyber Security'
        },
        specificInformation: {
          header: 'Cyber Security',
          description: 'We can train your forces in the art of cyber security, allowing you to protect your digital assets from cyber-attack. Our fully comprehensive learning programs will guide your personnel in detecting and responding to threats, guarding against data loss, and providing encryption. Our programs can ensure your teams can protect you from the most sophisticated forms of cyber threat.'
        },
      },
      {
        blankTile: true,
      }
    ]
  },
  {
    category: 'Hazardous material handling and disposal',
    url: '/what-we-offer/hazardous-material-handling-and-disposal',
    mainTile:
      {
        header: 'Munition disposal and recycling',
        image: {
          url: munitionDisposalAndRecycling,
          alternativeText: 'Munition disposal and recycling'
        },
        specificInformation: {
          header: 'Munition disposal and recycling',
          description: 'We offer removal of your outdated, obsolete or surplus munitions, leaving you secure in the knowledge that they will be disposed of responsibly and professionally while meeting or exceeding international environmental law.'
        },
      },
    topTiles: [
      {
        header: 'Hazardous Materials handling',
        image: {
          url: solutionsForHazardousMaterialsHandling,
          alternativeText: 'Hazardous Materials handling'
        },
        specificInformation: {
          header: 'Hazardous Materials handling',
          description: 'We can provide fully-licensed services to dispose of your hazardous materials by sea or land, ensuring that you and your personnel are protected and environmental laws are obeyed.'
        },
      },
      {
        buttonOnTop: true,
        header: 'Land clearance',
        image: {
          url: courseForLandClearanceOfUnexplodedOrdnance,
          alternativeText: 'Land clearance'
        },
        specificInformation: {
          header: 'Land clearance',
          description: 'We can provide your personnel the best in training for the identification, risk assessment, safe extraction and disposal of UXO hazards.'
        },
      },
      {
        blankTile: true,
      }
    ]
  },
]

export const isBrowser = typeof window !== 'undefined'

export const swappingTileSettings = {
  arrows: false,
  dots: false,
  fade: true,
  speed: 500,
  slidesToShow: 1,
}

export const videoJsOptions = {
  autoplay: true,
  controls: false,
  responsive: true,
  fluid: true,
}
