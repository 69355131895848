import React, { useState } from 'react'
import { Col, Row } from 'react-bootstrap'
import PropTypes from 'prop-types'
import { CategoriesTilePropType } from '../../propTypes'
import { noop } from 'lodash'
import SwappingTile from './SwappingTile'
import BlankTile from '../Tiles/BlankTile'

const SwappingTopTiles = ({ mainTiles, topTiles, specificProductOnClick }) => {
  const [goToTile, setGoToTile] = useState(0)

  const blankTileOnClick = () => {
    if (goToTile === mainTiles.length) {
      setGoToTile(0)
    } else {
      setGoToTile(goToTile + 1)
    }
  }

  return <Row>
    <SwappingTile
      xs={12}
      md={6}
      tiles={mainTiles}
      className='main-tile p-0'
      specificProductOnClick={specificProductOnClick}
      goToTile={goToTile}
    />
    <Col
      xs={12}
      md={6}
      className='top-tiles'
    >
      <Row>
        {topTiles.map((tiles, index) =>
          <SwappingTile
            key={index}
            md={6}
            xs={12}
            tiles={tiles}
            className='p-0'
            specificProductOnClick={specificProductOnClick}
            goToTile={goToTile}
          />
        )}
        <BlankTile
          xs={12}
          md={6}
          onClick={() => blankTileOnClick()}
        />
      </Row>
    </Col>
  </Row>
}

export default SwappingTopTiles

SwappingTopTiles.propTypes = {
  mainTile: PropTypes.arrayOf(CategoriesTilePropType),
  topTiles: PropTypes.arrayOf(CategoriesTilePropType),
  specificProductOnClick: PropTypes.func,
}

SwappingTopTiles.defaultProps = {
  mainTile: {},
  topTiles: [],
  specificProductOnClick: noop,
}
