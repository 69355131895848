import React from 'react'
import SectionWrapper from '../SectionWrapper/SectionWrapper'
import HeaderDetails from './HeaderDetails'
import PropTypes from 'prop-types'
import { ButtonLinkPropType, HeaderWithDescriptionPropType, StrapiImagePropType } from '../propTypes'
import MediaWithLines from '../MediaWithLines/MediaWithLines'
import classNames from 'classnames'
import useLocation from '../../hooks/useLocation'

const getHeaderClassName = (grayBackground, description, location) => classNames(
  { 'header-moved-left': location === '/connect-with-us' },
  { 'header-with-gray-background': grayBackground },
  { 'header-without-description': !description }
  , 'header'
)

const Header = ({ headerWithDescription, imageOrVideo, buttons, poster, grayBackground }) => {
  const location = useLocation()
  
  return <SectionWrapper className={getHeaderClassName(grayBackground, headerWithDescription?.description, location)}>
    <MediaWithLines
      className='header__background'
      media={imageOrVideo}
      numberOfLines={5}
      withGradient
      poster={poster}
    />
    <HeaderDetails
      headerWithDescription={headerWithDescription}
      buttons={buttons}
    />
  </SectionWrapper>
}

export default Header

Header.propTypes = {
  headerWithDescription: HeaderWithDescriptionPropType,
  image: StrapiImagePropType,
  buttons: PropTypes.arrayOf(ButtonLinkPropType),
  poster: StrapiImagePropType,
  grayBackground: PropTypes.bool
}

Header.defaultProps = {
  headerWithDescription: {},
  image: {},
  buttons: [],
  grayBackground: false
}
