import React from 'react'
import { Row } from 'react-bootstrap'
import Tile from './Tile'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import { CategoriesTilePropType } from '../../propTypes'
import { noop } from 'lodash'

const getBottomTilesClassName = (specificProductActive) => classNames(
  'bottom-tiles',
  { 'bottom-tiles-covered': specificProductActive }
)

const BottomTiles = ({ bottomTiles, specificProductOnClick, specificProductActive }) =>
  <Row className={getBottomTilesClassName(specificProductActive)}>
    {bottomTiles.map(tile =>
      <Tile
        xs={12}
        md={3}
        specificProductOnClick={specificProductOnClick}
        className='bottom-tile'
        key={tile.header}
        {...tile}
      />
    )}
  </Row>

export default BottomTiles

BottomTiles.propTypes = {
  bottomTiles: PropTypes.arrayOf(CategoriesTilePropType),
  specificProductOnClick: PropTypes.func,
  specificProductActive: PropTypes.string
}

BottomTiles.defaultProps = {
  bottomTiles: [],
  specificProductOnClick: noop,
  specificProductActive: ''
}
