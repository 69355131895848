import React from 'react'
import PropTypes from 'prop-types'
import { videoMimeTypes } from '../consts'
import ReactPlayer from 'react-player'

const Media = ({ mime, url, alternativeText, width, height, poster }) =>
  videoMimeTypes.includes(mime)
    ? <ReactPlayer
      width='100%'
      height='100%'
      loop
      muted
      playing
      playsinline
      controls={false}
      config={{ file: { attributes: { poster: poster?.url } } }}
      url={url}
    />
    : <img
      width={width}
      height={height}
      loading='lazy'
      src={url}
      alt={alternativeText}
    />

export default Media

Media.propTypes = {
  mime: PropTypes.string,
  url: PropTypes.string,
  alternativeText: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number
}

Media.defaultProps = {
  mime: '',
  url: '',
  alternativeText: '',
  width: 400,
  height: 200,
}
