import React from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import { StrapiImagePropType } from '../propTypes'
import { Link } from 'gatsby'

const getAboutUsTileClassName = (backgroundColor) => classNames(
  `about-us-tile-${backgroundColor}`,
  'about-us-tile'
)

const AboutUsTile = ({ header, image, backgroundColor, href }) =>
  <div className={getAboutUsTileClassName(backgroundColor)}>
    <Link
      to={href}
      className='d-flex justify-content-between flex-column h-100'
    >
      <h4 className='about-us-tile__header'>
        {header}
      </h4>
      <img
        height={image?.height}
        width={image?.width}
        src={image?.url}
        alt={image?.alternativeText}
      />
    </Link>
  </div>

export default AboutUsTile

AboutUsTile.propTypes = {
  header: PropTypes.string,
  image: StrapiImagePropType,
  backgroundColor: PropTypes.string,
  href: PropTypes.string
}

AboutUsTile.defaultProps = {
  header: '',
  image: {},
  backgroundColor: '',
  href: ''
}
