import React from 'react'
import MediaWithLines from '../MediaWithLines/MediaWithLines'
import PropTypes from 'prop-types'
import { StrapiImageWithLinesPropType } from '../propTypes'

const TileImage = ({ image, stripesBackground, className }) =>
  stripesBackground
    ? <>
      <div className='image-with-background d-flex justify-content-start'>
        <div className='image-with-background__bar' />
        <div className='image-with-background__bar d-flex justify-content-center align-items-center'>
          <img
            height={image?.height}
            width={image?.width}
            src={image?.url}
            alt={image?.alternativeText}
          />
        </div>
        <div className='image-with-background__bar' />
      </div>
    </>
    : <MediaWithLines
      media={image}
      numberOfLines={image?.numberOfLines}
      className={className}
    />

export default TileImage

TileImage.propTypes = {
  image: StrapiImageWithLinesPropType,
  stripesBackground: PropTypes.bool,
  className: PropTypes.string
}

TileImage.defaultProps = {
  image: {},
  stripesBackground: false,
  className: ''
}
