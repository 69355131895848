import React from 'react'
import { Col, Row } from 'react-bootstrap'
import Tile from './Tile'
import PropTypes from 'prop-types'
import { CategoriesTilePropType } from '../../propTypes'
import { noop } from 'lodash'

const TopTiles = ({ mainTile, topTiles, specificProductOnClick }) =>
  <Row>
    <Tile
      xs={12}
      md={6}
      specificProductOnClick={specificProductOnClick}
      className='main-tile'
      {...mainTile}
    />
    <Col
      xs={12}
      md={6}
      className='top-tiles'
    >
      <Row>
        {topTiles.map(tile =>
          <Tile
            xs={12}
            md={6}
            specificProductOnClick={specificProductOnClick}
            className='top-tile'
            key={tile.header}
            {...tile}
          />)}
      </Row>
    </Col>
  </Row>

export default TopTiles

TopTiles.propTypes = {
  mainTile: CategoriesTilePropType,
  topTiles: PropTypes.arrayOf(CategoriesTilePropType),
  specificProductOnClick: PropTypes.func,
}

TopTiles.defaultProps = {
  mainTile: {},
  topTiles: [],
  specificProductOnClick: noop,
}
