import React from 'react'
import PropTypes from 'prop-types'

const CategoriesDetails = ({ header, description }) =>
  <div className='categories-details d-flex justify-content-between align-items-center'>
    <h2>{header}</h2>
    <h5 className='font-montserrat text-end'>{description}</h5>
  </div>

export default CategoriesDetails

CategoriesDetails.propTypes = {
  header: PropTypes.string,
  description: PropTypes.string
}

CategoriesDetails.defaultProps = {
  header: '',
  description: ''
}
