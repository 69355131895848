import React from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'

const getParagraphClassName = (className, index) => classNames(
  className,
  'paragraph',
  {
    'first': index === 0
  },
)

const Paragraphs = ({ text, className }) =>
  text.split('\n').map((paragraph, index) =>
    <React.Fragment key={index}>
      <h5 className={getParagraphClassName(className, index)}>
        {paragraph}
      </h5>
    </React.Fragment>
  )

export default Paragraphs

Paragraphs.propTypes = {
  text: PropTypes.string,
  className: PropTypes.string,
}

Paragraphs.defaultProps = {
  text: '',
  className: '',
}
