import { categoriesTiles, serviceCategoriesTiles } from './consts'

export const getSpecificCategoriesTiles = (location, tiles) =>
  tiles.filter(category =>
    category.url === location ||
    category.url === (location + '/'))

export const getTilesForPage = (location) => getSpecificCategoriesTiles(location, categoriesTiles).length === 0
  ? getSpecificCategoriesTiles(location, serviceCategoriesTiles)
  : getSpecificCategoriesTiles(location, categoriesTiles)

export const getAllCategoriesForPage = (location) => getSpecificCategoriesTiles(location, categoriesTiles).length !== 0 ? categoriesTiles : serviceCategoriesTiles
