import React from 'react'
import { Col } from 'react-bootstrap'
import logo from '../../images/logoBlack.svg'

const FooterColWithLogo = () =>
  <Col
    xs={12}
    md={3}
    className='footer-col-with-logo'
  >
    <img
      alt='Mest'
      src={logo}
    />
    <div className='font-14 font-montserrat'>© 2021 MEST LTD. All rights reserved.</div>
  </Col>

export default FooterColWithLogo
