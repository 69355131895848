import React from 'react'
import Footer from '../Footer/Footer'
import '@fontsource/michroma/400.css'
import '@fontsource/montserrat/400.css'
import '@fontsource/montserrat/700.css'
import '../../styles/styles.scss'
import { renderSection } from '../index'
import NavBar from '../NavBar/NavBar'
import SEO from '../SEO/SEO'

const Layout = ({ sections, description }) =>
  <>
    <SEO description={description} />
    <NavBar />
    {sections?.map(renderSection)}
    <Footer />
  </>

export default Layout
