import React from 'react'
import SectionWrapper from '../SectionWrapper/SectionWrapper'
import { workWithUsTiles } from '../consts'
import WorkWIthUsTilesDetails from './WorkWIthUsTilesDetails'
import PropTypes from 'prop-types'
import { HeaderWithDescriptionPropType } from '../propTypes'
import Tiles from '../Tiles/Tiles'

const WorkWithUsTiles = ({ header, tiles }) => {
  tiles = tiles?.map((tile, index) => ({ ...tile, ...workWithUsTiles[index] }))

  return <SectionWrapper className='work-with-us-tiles'>
    <WorkWIthUsTilesDetails header={header} />
    <Tiles
      tiles={tiles}
      tileClassName='work-with-us-tile'
      stripesBackground
    />
  </SectionWrapper>
}

export default WorkWithUsTiles

WorkWithUsTiles.propTypes = {
  header: PropTypes.string,
  tiles: PropTypes.arrayOf(HeaderWithDescriptionPropType)
}

WorkWithUsTiles.defaultProps = {
  header: '',
  tiles: []
}
