import React from 'react'

const AboutUsBackground = ({}) =>
  <div className='about-us-background-wrapper'>
    <div className='about-us-background d-flex justify-content-start align-items-start'>
      {[...Array(6)].map((elem, index) =>
        <div
          key={index}
          className='about-us-background__bar'
        />
      )}
    </div>
  </div>

export default AboutUsBackground
