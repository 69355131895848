import React from 'react'
import { Row } from 'react-bootstrap'
import Tile from './Tile'
import PropTypes from 'prop-types'
import { TilePropType } from '../propTypes'

const Tiles = ({ tiles, tileClassName, imageClassName, stripesBackground }) =>
  <Row className='tiles d-flex justify-content-between align-content-center p-0 m-0'>
    {tiles.map(tile => <Tile
      key={tile.header}
      {...tile}
      className={tileClassName}
      imageClassName={imageClassName}
      stripesBackground={stripesBackground}
    />)}
  </Row>

export default Tiles

Tiles.propTypes = {
  tiles: PropTypes.arrayOf(TilePropType),
  tileClassName: PropTypes.string,
  imageClassName: PropTypes.string,
  stripesBackground: PropTypes.bool
}

Tiles.defaultProps = {
  tiles: [],
  tileClassName: '',
  imageClassName: '',
  stripesBackground: false
}
