import React from 'react'
import { Link } from 'gatsby'

const NavBarSubLink = ({ label, href }) =>
  <Link
    to={href}
    className='nav-bar-sub-link text-center'
  >
    {label}
  </Link>

export default NavBarSubLink
