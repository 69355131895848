import React from 'react'
import SectionWrapper from '../SectionWrapper/SectionWrapper'
import { Row } from 'react-bootstrap'
import { HeaderWithDescriptionPropType } from '../propTypes'
import PropTypes from 'prop-types'
import MestInBriefWithLogoDetails from './MestInBriefWithLogoDetails'
import MestInBriefWithLogoHeader from './MestInBriefWithLogoHeader'

const MestInBriefWithLogo = ({ headerWithDescription: { header, description }, headline }) =>
  <SectionWrapper className='mest-in-brief-with-logo'>
    <Row className='m-0 d-flex flex-column flex-md-row'>
      <MestInBriefWithLogoHeader header={header} />
      <MestInBriefWithLogoDetails
        headline={headline}
        description={description}
      />
    </Row>
  </SectionWrapper>

export default MestInBriefWithLogo

MestInBriefWithLogo.propTypes = {
  headerWithDescription: HeaderWithDescriptionPropType,
  headline: PropTypes.string
}

MestInBriefWithLogo.defaultProps = {
  headerWithDescription: {},
  headline: ''
}
