import React, { useState } from 'react'
import SectionWrapper from '../SectionWrapper/SectionWrapper'
import CategoriesNavButtons from './CategoriesNavButtons'
import CategoriesDetails from './CategoriesDetails'
import { HeaderWithDescriptionPropType } from '../propTypes'
import useLocation from '../../hooks/useLocation'
import { getTilesForPage } from '../helpers'
import Tiles from './Tiles/Tiles'

const Categories = ({ headerWithDescription }) => {
  const [activeProductInformation, setActiveProductInformation] = useState(null)
  const location = useLocation()

  const [currentCategoryTiles] = getTilesForPage(location)

  return <SectionWrapper className='categories'>
    {(currentCategoryTiles?.mainTile || currentCategoryTiles?.mainTiles)
      && <>
        <CategoriesDetails {...headerWithDescription} />
        <Tiles
          tiles={currentCategoryTiles}
          activeProductInformation={activeProductInformation}
          setActiveProductInformation={setActiveProductInformation}
        />
      </>}
    <CategoriesNavButtons />
  </SectionWrapper>
}

export default Categories

Categories.propTypes = {
  headerWithDescription: HeaderWithDescriptionPropType,
}

Categories.defaultProps = {
  headerWithDescription: {},
}
