import React from 'react'
import MestButton from '../MestButton/MestButton'
import PropTypes from 'prop-types'

const WorkWIthUsTilesDetails = ({ header }) =>
  <div className='work-with-us-tiles-details d-flex flex-column align-items-start align-items-md-end'>
    <h2 className='text-start text-md-end'>{header}</h2>
    <MestButton
      className='work-with-us-button'
      href='/what-we-offer'
      label='contact'
    />
  </div>

export default WorkWIthUsTilesDetails

WorkWIthUsTilesDetails.propTypes = {
  header: PropTypes.string,
}

WorkWIthUsTilesDetails.defaultProps = {
  header: ''
}
