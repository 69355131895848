import React from 'react'
import MediaWithLines from '../MediaWithLines/MediaWithLines'
import Slider from 'react-slick'
import { StrapiImagePropType } from '../propTypes'
import PropTypes from 'prop-types'

const sliderSettings = {
  arrows: false,
  dots: false,
  fade: true,
  autoplay: true,
  autoplaySpeed: 4000,
  speed: 1000,
  slidesToShow: 1,
}

const SwappingBackground = ({ images }) =>
  <div className='header__background'>
    <Slider {...sliderSettings}>
      {images?.map((background, index) =>
        <MediaWithLines
          key={index}
          media={background}
          numberOfLines={5}
          withGradient
        />
      )}
    </Slider>
  </div>

export default SwappingBackground

SwappingBackground.propTypes = {
  images: PropTypes.arrayOf(StrapiImagePropType)
}
SwappingBackground.defaultProps = {
  images: []
}
