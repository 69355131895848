import React from 'react'
import TileHeader from '../Tiles/TileHeader'
import TileButton from '../Tiles/TileButton'
import PropTypes from 'prop-types'
import { SpecificInformationPropType, StrapiImagePropType } from '../../propTypes'
import { noop } from 'lodash'

const setSpecificInformation = (specificInformation, image) => ({
  ...specificInformation,
  image: image
})

const SwappingTileContent = ({
  className,
  image,
  header,
  buttonLabel,
  buttonOnTop,
  specificProductOnClick,
  specificInformation
}) =>
  <div
    className='swapping-tile'
    onClick={() => specificProductOnClick(setSpecificInformation(specificInformation, image))}
  >
    <img
      className='swapping-tile__image'
      src={image?.url}
      alt={image?.alternativeText}
    />
    <TileHeader
      className={className}
      header={header}
      buttonOnTop={buttonOnTop}
    />
    {buttonLabel && <TileButton
      className={className}
      buttonLabel={buttonLabel}
      buttonOnTop={buttonOnTop}
    />}
  </div>

export default SwappingTileContent

SwappingTileContent.propTypes = {
  className: PropTypes.string,
  image: StrapiImagePropType.isRequired,
  header: PropTypes.string.isRequired,
  buttonLabel: PropTypes.string,
  buttonOnTop: PropTypes.bool,
  specificProductOnClick: PropTypes.func,
  specificInformation: SpecificInformationPropType
}

SwappingTileContent.defaultProps = {
  className: '',
  buttonLabel: 'more',
  buttonOnTop: false,
  specificProductOnClick: noop,
  specificInformation: {}
}
