import React from 'react'
import { Col } from 'react-bootstrap'
import { Link } from 'gatsby'
import PropTypes from 'prop-types'
import BlankTileContent from './BlankTileContent'

const BlankTile = ({ xs, md, onClick }) =>
  <Col
    xs={xs}
    md={md}
    onClick={onClick && onClick}
    className='category-tile blank-category-tile p-0'
  >
    {onClick
      ? <BlankTileContent label='next' />
      : <Link to='/what-we-offer'>
        <BlankTileContent label='Go back to offer' />
      </Link>
    }
  </Col>

export default BlankTile

BlankTile.propTypes = {
  xs: PropTypes.number,
  md: PropTypes.number,
  onClick: PropTypes.func
}

BlankTile.defaultProps = {
  xs: 6,
}
