import React from 'react'
import SpecificProduct from '../SpecificProduct'
import TopTiles from './TopTiles'
import BottomTiles from './BottomTiles'
import { CategoriesTilePropType, SpecificInformationPropType } from '../../propTypes'
import { noop } from 'lodash'
import PropTypes from 'prop-types'
import SwappingTopTiles from '../SwappingTiles/SwappingTopTiles'

const Tiles = ({ tiles, activeProductInformation, setActiveProductInformation }) =>
  <div className='categories-tiles'>
    {activeProductInformation
      && <SpecificProduct
        {...activeProductInformation}
        onClose={setActiveProductInformation}
      />}
    {tiles?.swappingTopTiles
      ? <SwappingTopTiles
        specificProductOnClick={setActiveProductInformation}
        {...tiles}
      />
      : <TopTiles
        specificProductOnClick={setActiveProductInformation}
        {...tiles}
      />
    }
    {tiles?.bottomTiles?.length > 0 &&
      <BottomTiles
        specificProductActive={activeProductInformation}
        specificProductOnClick={setActiveProductInformation}
        {...tiles}
      />}
  </div>

export default Tiles

Tiles.propTypes = {
  tiles: PropTypes.arrayOf(CategoriesTilePropType),
  activeProductInformation: SpecificInformationPropType,
  setActiveProductInformation: PropTypes.func
}

Tiles.defaultProps = {
  tiles: [],
  activeProductInformation: {},
  setActiveProductInformation: noop
}
