import React from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'

const getSectionWrapperClassName = (className) => classNames(
  className,
  'section-wrapper'
)

const getContentClassName = (className) => classNames(
  `${className}-content`,
  'section-wrapper-content'
)

const SectionWrapper = ({ className, children, backgroundImage, id }) =>
  <div
    className={getSectionWrapperClassName(className)}
    id={id}
  >
    {backgroundImage && backgroundImage}
    <div className={getContentClassName(className)}>
      {children}
    </div>
  </div>

export default SectionWrapper

SectionWrapper.propTypes = {
  className: PropTypes.string,
  id: PropTypes.string,
  children: PropTypes.node.isRequired,
  backgroundImage: PropTypes.node
}

SectionWrapper.defaultProps = {
  className: '',
  id: ''
}
