import React from 'react'
import MestButton from '../MestButton/MestButton'
import { HeaderWithDescriptionPropType } from '../propTypes'

const AboutUsDetails = ({ headerWithDescription }) =>
  <div className='about-us-tiles-details d-block d-md-flex align-items-center justify-content-between'>
    <h5 className='about-us-tiles-details__description font-montserrat'>
      {headerWithDescription?.description}
    </h5>
    <MestButton href='/' />
  </div>

export default AboutUsDetails

AboutUsDetails.propTypes = {
  headerWithDescription: HeaderWithDescriptionPropType,
}

AboutUsDetails.defaultProps = {
  headerWithDescription: {},
}
